import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Typography, TextField, Button, Box } from '@mui/material';
import axios from "../common/axios";


const LoginPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleLogin = async (e) => {
    e.preventDefault();

    const response = await axios.post(`/login`, { username, password }, { withCredentials: true });
    if (response.data.success) {
      const redirectUrl = new URLSearchParams(location.search).get('redirect');
      if (redirectUrl) {
        window.location.href = redirectUrl;
      } else {
        navigate('/');
      }
    } else {
      alert('Invalid username or password');
    }
  };

  const isButtonDisabled = !username || !password;

  return (
    <Box p={3} display="flex" flexDirection="column" alignItems="center">
      <Typography variant="h4" gutterBottom>
        Login
      </Typography>
      <form onSubmit={handleLogin}>
        <TextField
          label="Username"
          variant="outlined"
          margin="normal"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          required
          fullWidth
        />
        <TextField
          label="Password"
          variant="outlined"
          margin="normal"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
          fullWidth
        />
        <Button type="submit" variant="contained" color="primary" disabled={isButtonDisabled} fullWidth>
          Login
        </Button>
      </form>
    </Box>
  );
};

export default LoginPage;