import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from "../common/axios";
import { Typography, Grid, TextField, List, ListItem, ListItemIcon, ListItemText, Box, Divider, IconButton, Button, Accordion, AccordionSummary, AccordionDetails, Chip, AppBar, Toolbar, useMediaQuery, useTheme } from '@mui/material';
import { CloudDownload as CloudDownloadIcon, AttachFile as AttachFileIcon, ExpandMore as ExpandMoreIcon, Add as AddIcon, ExpandLess as ExpandLessIcon } from '@mui/icons-material';
import Swal from 'sweetalert2';
import _ from 'lodash';

const EmailPage = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const project = searchParams.get('project');
  const record = searchParams.get('record');
  const [emailData, setEmailData] = useState([]);
  const [expandedIndices, setExpandedIndices] = useState([]);
  const [initialEmailData, setInitialEmailData] = useState([]);
  const [allExpanded, setAllExpanded] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(970));
  
  console.log(emailData)

  const handleToggleAllAccordions = () => {
    setAllExpanded(!allExpanded);
    setExpandedIndices(allExpanded ? [] : emailData.map((_, index) => index));
  };

  // useEffect(() => {
  //   const newExpandedIndices = emailData
  //     .map((item, index) => (item.ClarifyCB === 'No' ? index : null))
  //     .filter((index) => index !== null);
  //   setExpandedIndices(newExpandedIndices);
  // }, [emailData]);

  const handleChange = (index) => (event, isExpanded) => {
    setExpandedIndices((prevExpandedIndices) =>
      isExpanded
        ? [...prevExpandedIndices, index]
        : prevExpandedIndices.filter((i) => i !== index)
    );
  };


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`/getTBE?project=${project}&record=${record}`);
        const data = response.data.data.map((v) => ({ ...v, SHIText: v.SHIText && v.SHIText.replaceAll("\\n", "\n"), VendorText: v.VendorText&&v.VendorText.replaceAll("\\n", "\n") }));
        setEmailData(data);
        setInitialEmailData(data);

        // Set initial expanded indices based on ClarifyCB
        const initialExpandedIndices = data
          .map((item, index) => (item.ClarifyCB === 'No' ? index : null))
          .filter((index) => index !== null);
        setExpandedIndices(initialExpandedIndices);
      } catch (error) {
        console.error('Error fetching email data:', error);
      }
    };

    fetchData();
  }, [project, record]);



  // useEffect(()=> setEmailData(initialEmailData), [initialEmailData])

  const handleCreateNewQuestion = () => {
    let maxLineAutoSeq = emailData.reduce((max, item) => {
      const seq = parseInt(item.LineAutoSeq, 10);
      return seq > max ? seq : max;
    }, 0);
  
    const newLineAutoSeq = (maxLineAutoSeq + 1).toString().padStart(5, '0');
  
    const newItem = {
      ClarifyCB: "No",
      SHIText: "",
      VendorText: "",
      vendor: emailData && emailData[0] && emailData[0].vendor,
      files: [],
      isNew: true,
      LineAutoSeq: newLineAutoSeq,
    };
  
    setEmailData([...emailData, newItem]);
    setExpandedIndices([...expandedIndices, emailData.length]);
  };

  const handleDownload = (fileData, fileName) => {
    const link = document.createElement('a');
    link.href = `data:application/octet-stream;base64,${fileData}`;
    link.download = fileName;
    link.click();
  };

  const handleVendorTextChange = (event, index) => {
    const newEmailData = JSON.parse(JSON.stringify(emailData));
    newEmailData[index].VendorText = event.target.value;
    setEmailData(newEmailData);
  };

  const handleFileChange = (event, index) => {
    const files = Array.from(event.target.files);
    const newEmailData = JSON.parse(JSON.stringify(emailData));

    files.forEach((file) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64Data = reader.result.split(',')[1];
        newEmailData[index].files.push({ name: file.name, data: base64Data });
        setEmailData(newEmailData);
      };
      reader.readAsDataURL(file);
    });
  };
  const handleSubmit = async () => {
    const filteredData = emailData.filter((item) => item.ClarifyCB !== 'Yes').map((item) => {
      const initialItem = initialEmailData.find((initial) => initial.li_num === item.li_num);
      const newFiles = item.files.filter((file) => {
        if (!initialItem) return true;
        return !initialItem.files.some((initialFile) => initialFile.name === file.name);
      });

      return {
        ...item,
        files: newFiles,
      };
    });

    if (filteredData.length === 0) {
      Swal.fire({
        title: '제출할 데이터가 없습니다.',
        icon: 'warning',
        confirmButtonText: '확인',
      });
      return;
    }

    Swal.fire({
      title: '정말 SHI로 보내겠습니까?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: '네',
      cancelButtonText: '아니오',
    }).then((result) => {
      if (result.isConfirmed) {
        axios.post('/submitTBE', { data: filteredData, record: record, project: project })
          .then((response) => {
            if (response.data.success) {
              Swal.fire({
                title: '성공적으로 제출되었습니다',
                icon: 'success',
                confirmButtonText: '확인',
              }).then(() => {
                window.location.reload();
              });
            } else {
              Swal.fire({
                title: '에러 발생',
                icon: 'error',
                text: response.data.message,
                confirmButtonText: '확인',
              });
            }
          })
          .catch((error) => {
            console.error('Error submitting data:', error);
            Swal.fire({
              title: '문제가 발생했습니다',
              icon: 'error',
              text: error.message,
              confirmButtonText: '확인',
            });
          });
      }
    });
  };

  const isSubmitDisabled = () => {
    const emailChanged = !_.isEqual(emailData, initialEmailData);
    const statusCheck = emailData && emailData[0] && emailData[0].recordStatus !== "Sent_to_Vendor";
    return !emailChanged || statusCheck;
  };

  return (
    <Box>
      <AppBar position="sticky" sx={{ backgroundColor: 'white', color: "#606060" }}>
        <Toolbar>

            <Box display="flex" alignItems="center" flexGrow={1}>
            {!isMobile && (
              <img src="/images/g1313.png" alt="Logo" style={{ marginRight: '16px', height: '32px' }} />
              )}
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                RFQ Technical Discussion Page
              </Typography>
            </Box>

          <Box>
            {isMobile ? (
              <IconButton
                color="inherit"
                onClick={handleToggleAllAccordions}
                style={{ marginRight: '16px' }}
              >
                {allExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </IconButton>
            ) : (
              <Button
                variant="text"
                startIcon={allExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                onClick={handleToggleAllAccordions}
                style={{ marginRight: '16px', color: '#606060' }}
              >
                {allExpanded ? 'Collapse All' : 'Expand All'}
              </Button>
            )}
            {isMobile ? (
              <IconButton
                color="inherit"
                onClick={handleCreateNewQuestion}
              >
                <AddIcon />
              </IconButton>
            ) : (
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                onClick={handleCreateNewQuestion}
              >
                Create New Question
              </Button>
            )}
          </Box>
        </Toolbar>
      </AppBar>
      <Box p={3}>
        <List>
          {emailData && emailData.map((item, index) => (
            <Accordion
              key={index}
              expanded={expandedIndices.includes(index)}
              onChange={handleChange(index)}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Box display="flex" alignItems="center">
                  <Typography>Number: {index + 1}</Typography>
                  &nbsp;&nbsp;&nbsp;&nbsp;Clarified:
                  <Box ml={1}>
                    <Chip
                      label={item.ClarifyCB}
                      color={item.ClarifyCB === 'Yes' ? 'primary' : 'default'}
                      size="small"
                    />
                  </Box>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Box mb={4} bgcolor="grey.100" p={2} borderRadius={4}>
                  <ListItem>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <TextField
                          label="SHI"
                          value={item.SHIText || ''}
                          fullWidth
                          multiline
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          label={item.vendor}
                          value={item.VendorText || ''}
                          onChange={(event) => handleVendorTextChange(event, index)}
                          fullWidth
                          multiline
                          disabled={item.ClarifyCB === 'Yes'} 
                        />
                      </Grid>
                    </Grid>
                  </ListItem>
                  {item.files && item.files.length > 0 && (
                    <Box mt={1} p={1} border={0} borderColor="grey.300" borderRadius={4} width="96%" sx={{ margin: "0 auto" }}>
                      <Typography variant="subtitle1" gutterBottom>
                        Attachments
                      </Typography>
                      <List dense>
                        {item.files.map((file, fileIndex) => (
                          <ListItem key={fileIndex}>
                            <ListItemIcon>
                              <IconButton size="small" onClick={() => handleDownload(file.data, file.name)}>
                                <CloudDownloadIcon />
                              </IconButton>
                            </ListItemIcon>
                            <ListItemText primary={file.name} />
                          </ListItem>
                        ))}
                      </List>
                    </Box>
                  )}
                  <Box mt={1} display="flex" justifyContent="flex-end">
                    <input
                      accept="*"
                      style={{ display: 'none' }}
                      id={`file-input-${index}`}
                      type="file"
                      multiple
                      onChange={(event) => handleFileChange(event, index)}
                      disabled={item.ClarifyCB === 'Yes'}
                    />
                    <label htmlFor={`file-input-${index}`}>
                      <Button
                        variant="outlined"
                        size="small"
                        component="span"
                        startIcon={<AttachFileIcon />}
                        disabled={item.ClarifyCB === 'Yes'}
                      >
                        Attach Files
                      </Button>
                    </label>
                  </Box>
                </Box>
              </AccordionDetails>
            </Accordion>
          ))}
        </List>
        <Box
          mt={4}
          display="flex"
          justifyContent="center"
          position="fixed"
          bottom={0}
          left={0}
          right={0}
          p={2}
          bgcolor="background.paper"
          boxShadow={3}
          zIndex={1000}
        >
          <Button variant="contained" color="primary" onClick={handleSubmit} disabled={isSubmitDisabled()}>
            Submit
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default EmailPage;